/* eslint-disable */

import styled, { css } from 'styled-components';
import { shade } from 'polished';

import printSteps from '../utils/printSteps';
import { DEFAULT_COLORS } from '../utils/colors';
import InfoInputIcon from '../assets/alert-circle-info.svg';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HeaderMain = styled.div`
    display: flex;
    position: relative;
    margin: 1rem 1rem 1.5rem 1rem;
    align-items: center;
    min-height: 18px;
    justify-content: space-between;
    @media all and (min-width: 576px) {
        justify-content: center;
    }

    & + div {
        margin-top: 0;
    }
`;

export const LanguageSwitch = styled.div`
    display: flex;
    justify-content: flex-end;

    img {
        cursor: pointer;
        border-radius: 0.25rem;
        opacity: 0.3;
    }

    & img:not(:first-of-type) {
        margin-left: 0.5rem;
    }

    .active {
        opacity: 1;
    }

    @media all and (min-width: 576px) {
        position: absolute;
        top: 0;
        right: 0;
    }
`;

export const StepCounter = styled.div`
    display: flex;
    align-items: center;

    @media all and (min-width: 576px) {
        margin-top: 2rem;
    }

    div {
        width: 8px;
        height: 8px;
        background-color: ${DEFAULT_COLORS.white};
        margin: 0 10px;
        border-radius: 50%;

        @media all and (max-width: 575px) {
            box-shadow: inset 0px 0px 0px 1px ${DEFAULT_COLORS.blue_200};
        }
    }

    ${props => printSteps(props)};

    ${props =>
        !props.isVisible &&
        css`
            display: none;
        `};
`;

export const ContentForm = styled.div`
    padding: 16px;
    margin-top: 4em;
    @media all and (min-width: 576px) {
        padding: 40px;
        max-width: 510px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
    }
    width: 100%;
    border-radius: 10px;
    background-color: ${DEFAULT_COLORS.white};
`;

export const HeaderStep = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    img {
        width: 100%;
        max-width: 166px;
        margin-bottom: 32px;
        margin-left: auto;
        margin-right: auto;
    }

    h2 {
        line-height: 32px;
    }

    span {
        display: block;
        margin-top: 16px;
        font-size: 14px;
    }

    img ~ h2,
    img ~ span {
        text-align: center;
    }
`;

export const HeaderStepInformations = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    img {
        width: 100%;
        max-width: 166px;
        margin-bottom: 32px;
        margin-left: auto;
        margin-right: auto;
    }

    h2 {
        line-height: 32px;
    }

    span {
        display: block;
        margin-top: 16px;
        font-size: 14px;
    }

    img ~ h2,
    img ~ span {
        text-align: left;
    }
`;

export const StepVisibleControl = styled.div`
    display: none;

    ${props =>
        props.visible &&
        css`
            display: flex;
            flex-direction: column;
        `}
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
`;

export const EmptyError = styled.div`
    span {
        font-size: 12px;
        color: ${DEFAULT_COLORS.orange_validation};
    }
`;

export const DangerError = styled.div`
    span {
        font-size: 12px;
        color: ${DEFAULT_COLORS.red_validation};
    }
`;

export const HelpText = styled.div`
    margin-top: 4px;
    color: ${DEFAULT_COLORS.gray_500};
    font-size: 12px;
`;

export const FormGroupInline = styled.div`
    display: flex;

    & > div {
        flex: 1;
    }

    & > div + div {
        margin-bottom: 20px;
        margin-left: 16px;
    }
`;

export const FormGroup = styled.div`
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }

    label {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 8px;
        color: ${DEFAULT_COLORS.gray_600};
    }

    textarea {
        display: block;
        width: 100%;
        min-height: 100px;
        padding: 10px 12px;
        border: none;
        border-radius: 4px;
        resize: none;
        &:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }
`;

export const GroupButtonsRight = styled.div`
    display: flex;
    justify-content: flex-end;

    button:nth-of-type(2) {
        margin-left: 24px;
    }
`;

export const LogoImg = styled.img`
    width: 166px;
    margin-bottom: 32px;
`;

export const BodyImg = styled.img`
    width: 100%;
    max-width: 340px;
    margin-bottom: 24px;
`;

export const EndViewTextWelcome = styled.div`
    text-align: center;
    font-weight: 400;
    color: ${DEFAULT_COLORS.gray_700};

    @media all and (min-width: 576px) {
        & + div {
            margin-top: 1rem;
        }
    }

    @media all and (max-width: 576px) {
        & + div {
            margin-bottom: 2.5rem;
        }
    }
`;

export const ContainerTooltip = styled.div`
    display: inline-block;

    & .__react_component_tooltip {
        background-color: ${DEFAULT_COLORS.gray_900};
        border-radius: 0.25rem;
        padding: 5px 8px;
        font-size: 12px;
        font-weight: 500;
    }

    & .__react_component_tooltip.show {
        opacity: 1;
    }
`;

export const IconTooltip = styled.div`
    background-image: url(${InfoInputIcon});
    color: ${DEFAULT_COLORS.gray_400};
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
    background-size: 16px;
    vertical-align: middle;
    display: inline-block;
    margin-top: -2px;
`;

export const ServiceTerms = styled.span`
    font-size: 12px;

    a {
        text-decoration: none;
        color: ${DEFAULT_COLORS.blue_400};

        &:hover {
            text-decoration: underline;
        }
    }
`;
