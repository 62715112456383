import { css } from 'styled-components';
import { DEFAULT_COLORS } from '../utils/colors';

export default function printSteps({ stepVisible }) {
    let style = '';

    for (let i = 1; i <= stepVisible; i++) {
        style += `
            #s${i}{
                background-color: ${DEFAULT_COLORS.blue_200};
            }
        `;
    }

    return css`
        ${style}
    `;
}
