import styled from 'styled-components';
import { DEFAULT_COLORS } from '../../utils/colors';

const handleColorColor = type => {
    switch (type) {
      case "danger":
        return `${DEFAULT_COLORS.red_500}`;
      default:
        return `${DEFAULT_COLORS.gray_700}`;
    }
};

const handleBackgroundColor = type => {
    switch (type) {
      case "danger":
        return `${DEFAULT_COLORS.red_100}`;
      case "info":
        return `${DEFAULT_COLORS.gray_100}`;
      default:
        return `${DEFAULT_COLORS.gray_300}`;
    }
};

export const ContainerAlert = styled.div`
    display: flex;
    align-items: flex-start;
    padding: .75rem 1rem;
    border-radius: 8px;
    color: ${({ type }) => handleColorColor(type)};
    background-color: ${({ type }) => handleBackgroundColor(type)};

    & > div {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
    }

    & img {
        margin-right: .75rem;
    }
    
    & a {
      color: inherit;
      font-weight: bold;
    }  
`;