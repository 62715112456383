/* eslint-disable */

import styled from 'styled-components';
import { DEFAULT_COLORS } from '../../utils/colors';

export const ModalBackdrop = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: ${props => (props.open ? '1000' : '-1')};
    opacity: ${props => (props.open ? '1' : '0')};
    ${props => (props.open ? '' : 'transition: all 150ms ease 350ms')}
`;

export const ModalContainer = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: ${props => (props.open ? '0' : '-100%')};
    bottom: ${props => (props.open ? '0' : '100%')};
    z-index: 1001;
    transition: all 350ms ease;
    display: flex;
`;

export const ModalBox = styled.div`
    display: block;
    max-width: 600px;
    margin: 1.75rem auto;
    align-self: center;
    width: 100%;
    background-color: #fff;
    border-radius: 0.25rem;
`;

export const Header = styled.div`
    display: block;
    text-align: center;
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    position: relative;

    & .close {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        border: none;
    }
`;

export const Body = styled.div`
    display: block;
    padding: 1.5rem 2rem;
    text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
`;

export const Footer = styled.div`
    display: flex;
    padding: 1rem;
    border-top: 1px solid ${DEFAULT_COLORS.gray_100};
    justify-content: flex-end;
`;
