import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import StepCustomerInformation from './Flow/StepCustomerInformation';
import RegistrationCompleted from './Flow/RegistrationCompleted';
import {
    ContentForm,
    HeaderStep,
} from '../../../styles/global';

import { AuthContext } from '../../../contexts/AuthContext';
import { LOCALES, I18nProvider } from '../../../i18n';

const Steps = () => {
    const { isAuth } = useContext(AuthContext);
    const history = useHistory();

    //debugger;
    if (!isAuth) {
        history.push('/comercial');
    }

    return (
        <I18nProvider locale={LOCALES.PORTUGUESE}>
            <ContentForm>
                <StepCustomerInformation>
                    <HeaderStep>
                        <h2>Informações do cliente</h2>
                    </HeaderStep>
                </StepCustomerInformation>

                <RegistrationCompleted />
            </ContentForm>
        </I18nProvider>
    );
};

export default Steps;
