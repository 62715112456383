import { LOCALES } from '../locales';

export default {
    [LOCALES.ENGLISH]: {
        'site-title':'iClips | Create your free account',
        'loading-label': 'Loading...',
        'backBtn-label': 'Back',
        'nextBtn-label': 'Next',
        'startNowBtn-label': 'Create iClips',
        'yes-label': 'Yes',
        'no-label': 'No',
        'dontKnow-label': 'Dont know',
        'select-one-below': 'Select one of the options below:',
        'publicity-label': 'Publicity',
        'branding-label': 'Branding',
        'contentMkt-label': 'Content marketing',
        'design-label': 'Design',
        'pressOffice-label': 'Press office',
        'seoSem-label': 'SEO/SEM',
        'socialMedia-label': 'Social media',
        'webAndMobileDesign-label': 'Web design and mobile design',
        'noneOptionOfTheAbove-label': 'None of the options above',
        '1a5-label': '1 to 5',
        '5a10-label': '5 to 10',
        '10a25-label': '10 to 25',
        '25a50-label': '25 to 50',
        'moreThan50-label': 'more than 50',
        'iclipsDomain-helper-label': 'This will be your iClips domain adress',

        'terms-of-service-agreement1': 'By clicking ',
        'terms-of-service-agreement2': '"Access iClips"',
        'terms-of-service-agreement3': ' you agree with ',
        'terms-of-service-agreement4': 'terms and policies.',
        'recaptcha-is-required': 'reCAPTCHA is required.',
        'terms-of-service-link': 'https://rockcontent.com/legal/msa/',

        'client-step01-title':
            'Manage and integrate your entire agency for free',
        'client-step01-subTitle':
            'iClips is the ideal solution to orchestrate your creative projects and campaigns.',
        'client-step01-label-name': `Full name`,
        'client-step01-label-email': `E-mail`,
        'client-step01-client-exists': `We have identified that you are already a Rock Content customer. To continue, please enter your password and login to your Rock account`,
        'client-step01-label-phone': `Phone`,
        'client-step01-forgot-password': `Forgot my password`,
        'client-step01-label-phone-tooltip' : `We ask for your phone number<br>to contact you and offer upgrades<br>for your iClips. We will not share<br> your phone number with any<br> third party.`,
        'client-step01-signUpBtn-label': `Sign up for free!`,
        'client-step01-login-label': `Login`,
        'client-step01-company-size' : 'Company size',
        'client-step01-placeholder-example-name' : 'E.g.: Bruna Santos',
        'client-step01-placeholder-example-email' : 'E.g.: bruna.santos@rckmarketing.com',
        'client-step01-placeholder-example-company' : 'E.g.: RCK Marketing',
        'client-step01-more-informations': `We need more information to create your iClips`,
        'client-step01-more-informations-sso': `Confirm your information to create your iClips`,
        'client-step02-title':
            'Does your company provide any advertising services to third parties?',
        'client-step02-subTitle': 'Are you part of an agency?',
        'client-step02-subTitle-tooltip':
            'Agencies provide advertising<br>services to third parties.<br>Marketing teams perform services<br>only for the company itself.',

        'client-step03a-title':
            'What is the main service performed at your agency?',
        'client-step03b-title': 'How many people are on your marketing team?',

        'client-step04-title': 'We are almost there!',
        'client-step04-nameOfCompany': 'Company name',
        'client-step04-numberEmployee': 'What is the number of employees?',
        'client-step04-mostImportantTeam':
            'What is most important to your team today?',
        'client-step04-mostImportantAgency':
            'What is most important to your agency today?',

        'client-step04-mostImportantOptionsVal0': 'Choose an option',
        'client-step04-mostImportantOptionsVal1':
            'Integrate project, media and financial management into a single platform',
        'client-step04-mostImportantOptionsVal2':
            "Measure the profit / loss margin of your agency's clients and projects",
        'client-step04-mostImportantOptionsVal3':
            'Identify and reduce refractions in your agency',
        'client-step04-mostImportantOptionsVal4':
            "Manage the agency's projects and demands",
        'client-step04-mostImportantOptionsVal5':
            'Do the financial management of the agency',
        'client-step04-mostImportantOptionsVal6': 'Agency media management',
        'client-step04-createYourDomain-label': 'Choose your domain',
        'client-step04-createYourDomain-helpLabel': 'This will be your iClips address',
        'client-step04-dontHaveCompany': "I don't have a company",
        'client-step04-Individual': 'Individual',
        'client-step04-confirmaEmail': 'Your access data will be sent to',
        'client-step04-yourDomainUrl': 'companyname',

        'client-lastScreen-title': 'Welcome!',
        'client-lastScreen-subTitle': 'Your account has been successfully created! We would like to welcome you and say that we are very happy to have chosen our solution to manage your processes.',
        'client-lastScreen-subTitle2' : 'Your iClips account was successfully created. Soon you will get your credentials in the email',
        'client-lastScreen-btn-label': 'Continue to the platform',

        'validation-userName': 'Please, enter the user name.',
        'validation-email': 'Please enter email',
        'validation-phone': 'Invalid phone.',
        'validation-companyName': 'Please enter the company name.',
        'validation-requiredField': 'Required field',
        'validation-domainMinCharacters':
            'Domain name must have at least 3 characters.',
        'validation-domainMaxCharacters':
            'Domain name must have a maximum of 15 caracteres.',
        'validation-verifyingDomain': 'Verifying domain disponibility...',
        'validation-validDomain': 'Valid domain',
        'validation-onlyLetters': 'A-z characters are allowed.',
        'validation-domainMustHaveMax': 'The domain must have at most',
        'validation-domainMustHaveMin': 'The domain must have at least',
        'characters-label': ' characters.',
        'alert-text-error' : 'An error has occurred. Try again in a few moments. If the error persists, contact {suporteLink}.',
        'ssoPasswordError' : 'The password entered is not correct. If the error persists, contact {suporteLink}.',
        'support' : 'support',
        'login' : 'Login',
        'loginPlaceholder' : 'E.g.: brunasantos',
        'loginTip' : 'Use only letters and numbers. Do not use spaces.',
        'password' : 'Password',
        'confirmPassword' : 'Confirm your password',
        'passwordPlaceholder' : 'Type your password',
        'confirmPasswordPlaceholder' : 'Re-enter your password',
        'passwordHelpTextMinLength' : 'Minimum of 6 characters.',
        'passwordHelpText' : 'Should have at least 6 characters and include at least one number, one uppercase letter and one lowercase letter.',
        'passwordTooltip': 'Your password is necessary to create your iClips and link it to your Rock Account. Enter here the same password you use to access other Rock Content products.',
        'accessiClips-button': 'Access iClips',
        'text-initial': 'Sign up for a free trial.',
    },
};
