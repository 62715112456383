import { LOCALES } from '../locales';

export default {
    [LOCALES.PORTUGUESE]: {
        'site-title':'iClips | Crie sua conta grátis',
        'loading-label': 'Carregando...',
        'backBtn-label': 'Voltar',
        'nextBtn-label': 'Avançar',
        'startNowBtn-label': 'Criar iClips',
        'yes-label': 'Sim',
        'no-label': 'Não',
        'dontKnow-label': 'Não sei',
        'select-one-below': 'Selecione uma das opções abaixo:',
        'publicity-label': 'Publicidade',
        'branding-label': 'Branding',
        'contentMkt-label': 'Marketing de conteúdo',
        'design-label': 'Design',
        'pressOffice-label': 'Assessoria de imprensa',
        'seoSem-label': 'SEO/SEM',
        'socialMedia-label': 'Social media',
        'webAndMobileDesign-label': 'Web design e mobile design',
        'noneOptionOfTheAbove-label': 'Nenhuma das opções acima',
        '1a5-label': '1 a 5',
        '5a10-label': '5 a 10',
        '10a25-label': '10 a 25',
        '25a50-label': '25 a 50',
        'moreThan50-label': 'mais de 50',
        'iclipsDomain-helper-label': 'Este será o endereço do seu iClips',

        'terms-of-service-agreement1': 'Ao clicar em ',
        'terms-of-service-agreement2': '"Acessar iClips"',
        'terms-of-service-agreement3': ' você concorda com os ',
        'terms-of-service-agreement4': 'Termos de Serviço.',
        'recaptcha-is-required': 'reCAPTCHA é obrigatório.',
        'terms-of-service-link': 'https://rockcontent.com/br/legal/msa/',

        'client-step01-title':
            'Gerencie e integre toda a sua agência gratuitamente',
        'client-step01-subTitle': `iClips é a solução ideal para agências e equipes de marketing.`,
        'client-step01-client-exists':`Identificamos que você já é cliente Rock Content. Para continuar, por favor, informe sua senha e faça login em sua conta da Rock.`,
        'client-step01-label-name': `Nome completo`,
        'client-step01-forgot-password': `Esqueci minha senha`,
        'client-step01-label-email': `E-mail`,
        'client-step01-label-phone': `Telefone`,
        'client-step01-label-phone-tooltip': `Pedimos o seu telefone para entrar<br>em contato e oferecer upgrades<br>para o seu iClips. Nós não iremos<br>compartilhar o seu número de<br>telefone com terceiros.`,
        'client-step01-signUpBtn-label': `Cadastrar gratuitamente`,
        'client-step01-login-label': `Fazer Login`,
        'client-step01-nextStep-label': `Próximo passo`,
        'client-step01-company-size': 'Número de colaboradores',
        'client-step01-placeholder-example-name': 'Ex.: Bruna Santos',
        'client-step01-placeholder-example-email': 'Ex.: bruna.santos@rckmarketing.com',
        'client-step01-placeholder-example-company': 'Ex.: RCK Marketing',
        'client-step01-more-informations': `Precisamos de algumas informações para criar seu iClips`,
        'client-step01-more-informations-sso': `Confirme suas informações para criar seu iClips`,
        'client-step02-title':
            'Sua empresa presta algum serviço publicitário para terceiros?',
        'client-step02-subTitle':
            'Você faz parte de uma agência?',
        'client-step02-subTitle-tooltip': `Agências prestam serviços<br>publicitários para terceiros.<br>Equipes de marketing realizam<br>serviços apenas para a<br>própria empresa.`,

        'client-step03a-title':
            'Qual é o principal serviço realizado na sua agência?',
        'client-step03b-title':
            'Quantas pessoas fazem parte da sua equipe de marketing?',

        'client-step04-title': 'Estamos quase lá!',
        'client-step04-nameOfCompany': 'Nome da empresa',
        'client-step04-numberEmployee': 'Qual o número de funcionários?',
        'client-step04-mostImportantTeam':
            'O que é mais importante para a sua equipe, atualmente?',
        'client-step04-mostImportantAgency':
            'O que é mais importante para a sua agência, atualmente?',

        'client-step04-mostImportantOptionsVal0': 'Selecione uma opção',
        'client-step04-mostImportantOptionsVal1':
            'Integrar em uma única plataforma a gestão de projetos, mídia e financeiro',
        'client-step04-mostImportantOptionsVal2':
            'Mensurar a margem de lucro/prejuízo de clientes e projetos da sua agência',
        'client-step04-mostImportantOptionsVal3':
            'Identificar gargalos e reduzir refações na sua agência',
        'client-step04-mostImportantOptionsVal4':
            'Gerenciar os projetos e demandas da agência',
        'client-step04-mostImportantOptionsVal5':
            'Fazer a gestão financeira da agência',
        'client-step04-mostImportantOptionsVal6':
            'Fazer a gestão de mídia da agência',
        'client-step04-createYourDomain-label': 'Escolha seu domínio',
        'client-step04-createYourDomain-helpLabel': 'Este será o endereço do seu iClips',
        'client-step04-dontHaveCompany': 'Não tenho empresa',
        'client-step04-Individual': 'Individual',
        'client-step04-confirmaEmail': 'Seus dados de acesso serão enviados para o e-mail',
        'client-step04-yourDomainUrl': 'nomedaempresa',

        'client-lastScreen-title': 'Agora você faz parte do iClips!',
        'client-lastScreen-subTitle': 'Sua conta foi criada com sucesso! Gostaríamos de te dar as boas-vindas e dizer que estamos muito felizes por ter escolhido nossa solução para gerenciar seus processos.',
        'client-lastScreen-subTitle2': 'Seu iClips está sendo criado e, dentro de alguns instantes, você receberá os dados de acesso no e-mail',
        'client-lastScreen-btn-label': 'Continuar para a plataforma',

        'validation-userName': 'Por favor, informe o nome do usuário.',
        'validation-email': 'Por favor, informe o e-mail',
        'validation-phone': 'Telefone inválido.',
        'validation-companyName': 'Por favor, informe o nome da empresa.',
        'validation-requiredField': 'Campo obrigatório',
        'validation-domainMinCharacters':
            'Domínio deve ter pelo menos 3 caracteres.',
        'validation-domainMaxCharacters':
            'Domínio deve ter no máximo 26 caracteres.',
        'validation-verifyingDomain':
            'Verificando disponibilidade de domínio...',
        'validation-validDomain': 'Domínio válido',
        'validation-onlyLetters': 'É permitido caracteres de a-z.',
        'validation-domainMustHaveMax': 'O domínio deve ter no máximo ',
        'validation-domainMustHaveMin': 'O domínio deve ter no mínimo ',
        'characters-label': ' caracteres.',
        'alert-text-error': 'Ocorreu um erro. Tente novamente em alguns instantes. Caso o erro persista, entre em contato com o {suporteLink}.',
        'ssoPasswordError': 'A senha informada não está correta. Caso o erro persista, entre em contato com o {suporteLink}.',
        'support': 'suporte',
        'login': 'Login',
        'loginPlaceholder': 'Ex.: brunasantos',
        'loginTip': 'Use apenas letras e números. Não use espaços.',
        'password': 'Senha',
        'confirmPassword': 'Confirme sua senha',
        'passwordPlaceholder': 'Digite sua senha',
        'confirmPasswordPlaceholder': 'Digite sua senha novamente',
        'passwordHelpTextMinLength': 'Mínimo de 6 caracteres.',
        'passwordHelpText': 'Deve ter no mínimo 6 caracteres e incluir ao menos um número, uma letra minúscula e outra maiúscula.',
        'passwordTooltip': 'Sua senha é necessária para criar seu iClips com segurança e vinculá-lo à sua conta da Rock. Informe aqui a mesma senha que você já utiliza para acessar outros produtos da Rock.',
        'accessiClips-button': 'Acessar iClips',
        'text-initial': 'Cadastre-se para fazer um teste gratuito.',
    },
};
