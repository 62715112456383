export default function hideHubspotFeature() {
    var timer;

    timer = setInterval(() => {
        var hubspotContainer = document.getElementById("hubspot-messages-iframe-container");
        if(hubspotContainer) {
            hubspotContainer.style.setProperty("display", "none", "important");
            clearTimeout(timer);
        } 
    }, 100);
}
