/* eslint-disable */

import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiCalendar } from 'react-icons/fi';
import { useField } from '@unform/core';
import { getErrorMessage } from '../../utils/manualValidations';
import { cep, currency, date, cnpj, maskTelephone, maskTelephoneNA } from '../../utils/masks';
import ReactTooltip from 'react-tooltip';

import { ContainerTooltip, IconTooltip } from '../../styles/global';
import { Container, Error, EmptyError, CurrencyPrefix } from './styles';
import { HelpText } from '../../styles/global';

const Input = ({
    formRef,
    mask,
    id,
    name,
    type,
    label,
    halfWidth,
    inputValue,
    onChange,
    onBlur,
    onFocus,
    onSelect,
    isDatepicker,
    setPasswordValue,
    passwordValue,
    ...props
}) => {
    const inputRef = useRef(null);

    const [isValid, setIsValid] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const [isFocused, setIsFocused] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    const [isEmailInvalid, setIsEmailInvalid] = useState(null);
    const [isTelInvalid, setIsTelInvalid] = useState(null);
    const [isCNPJInvalid, setIsCNPJInvalid] = useState(null);

    const [inputVal, setInputVal] = useState('');

    const { fieldName, error, registerField, clearError } =
        useField(name);

    function setErrorField(errorMessage) {
        // eslint-disable-next-line no-unused-expressions
        formRef?.current.setFieldError(fieldName, errorMessage);
    }

    function handleInputBlur(e) {
        const value = e.target.value;
        const type =
            e.currentTarget.getAttribute('data-custom-type') ||
            e.currentTarget.type;

        if (!value) {
            clearError(fieldName);
            setIsEmpty(true);
            setIsValid(false);
        } else {
            setIsEmpty(false);
            if (type === 'email') {
                const emailRegex =
                    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                const isEmailValid = emailRegex.test(value);

                if (!isEmailValid) {
                    setIsEmailInvalid(true);
                    setIsValid(false);
                } else {
                    setIsValid(true);
                }
            } else if (type === 'tel') {
                if (value.length < 14) {
                    setIsTelInvalid(true);
                    setIsValid(false);
                } else {
                    setIsValid(true);
                }
            } else if (type === 'text') {
                if (mask === 'cnpj') {
                    if (value.length < 14) {
                        setIsCNPJInvalid(true);
                        setIsValid(false);
                        setIsEmpty(true);
                    } else {
                        setIsValid(true);
                        setIsEmpty(false);
                    }
                } else {
                    setIsValid(true);
                }
            } else if (type === 'login') {
                const loginRegex =
                    /^[a-z0-9àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇ]*$/gi;
                const isLoginValid = loginRegex.test(value);

                if (['admin'].includes(value.toLowerCase())) {
                    setErrorField(
                        getErrorMessage('loginNotAllowed', props.lang),
                    );
                    setIsValid(false);
                } else {
                    if (!isLoginValid) {
                        setErrorField(
                            getErrorMessage(`Invalid${fieldName}`, props.lang),
                        );
                        setIsValid(false);
                    } else {
                        if (
                            parseInt(value.length) <
                            parseInt(e.currentTarget.getAttribute('minLength'))
                        ) {
                            setErrorField(
                                getErrorMessage('MinLogin', props.lang),
                            );
                            setIsValid(false);
                        } else {
                            setIsValid(true);
                        }
                    }
                }
            } else if (type === 'password') {
                // eslint-disable-next-line no-unused-expressions
                const passwordRegex = new RegExp(
                    '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,}).*$',
                );
                const isPasswordValid = passwordRegex.test(value);

                if (!isPasswordValid) {
                    setErrorField(
                        getErrorMessage('InvalidPassword', props.lang),
                    );
                    setIsValid(false);
                } else {
                    setIsValid(true);
                }
            } else if (type === 'confirmpassword') {
                if (
                    parseInt(value.length) <
                    parseInt(e.currentTarget.getAttribute('minLength'))
                ) {
                    setErrorField(getErrorMessage('MinPassword', props.lang));
                    setIsValid(false);
                } else {
                    if (value != passwordValue) {
                        setErrorField(
                            getErrorMessage('NotEqualsPassword', props.lang),
                        );
                        setIsValid(false);
                    } else {
                        setIsValid(true);
                    }
                }
            } else if (type === '') {
            } else {
                setIsValid(true);
            }
        }

        onBlur && onBlur(e);
    }

    const handleInputFocus = useCallback(e => {
        clearError(fieldName);
        setIsTouched(true);
        setIsFocused(true);
        e.target.autocomplete = "off";

        onFocus && onFocus(e);
    }, []);

    function autoHeightTextarea(e) {
        e.target.style.boxSizing = 'border-box';
        let offset = e.target.offsetHeight - e.target.clientHeight;
        e.target.style.height = 'auto';
        e.target.style.height = e.target.scrollHeight + offset + 'px';
    }

    const handleInputChange = useCallback(e => {
        const value = e.target.value;

        clearError(fieldName);

        setInputVal(value);
        if (setPasswordValue) {
            setPasswordValue(value);
        }
        setIsEmailInvalid(false);
        setIsTelInvalid(false);
        setIsCNPJInvalid(false);

        if (props?.multline) {
            autoHeightTextarea(e);
        }

        onChange && onChange(value);
    }, []);

    const handleDatepickerSelected = useCallback(date => {
        // debugger;
        const value = date;
        setInputVal(value);
        setIsEmpty(false);
        setIsValid(true);

        if (value) {
            clearError();
        }

        onSelect && onSelect(value);
    }, []);

    const handleDatepickerChange = useCallback(date => {
        // debugger;
        const value = date;
        setInputVal(value);
        setIsEmpty(false);
        setIsValid(true);

        onChange && onChange(value);
    }, []);

    const handleInputKeyUp = useCallback(
        e => {
            if (mask === 'cep') cep(e);
            if (mask === 'currency') currency(e);
            if (mask === 'date') date(e);
            if (mask === 'maskTelephone') maskTelephone(e);
            if (mask === 'maskTelephoneNA') maskTelephoneNA(e);
            if (mask === 'cnpj') cnpj(e);
        },
        [mask],
    );

    function renderTooltip(message) {
        return (
            <ContainerTooltip>
                <IconTooltip data-tip={message} />
                <ReactTooltip effect="solid" place="right" multiline={true} />
            </ContainerTooltip>
        );
    }

    useEffect(() => {
        if (isDatepicker) {
            registerField({
                name: fieldName,
                ref: inputRef.current,
                clearValue: ref => {
                    ref.clear();
                },
                setValue: (e, v) => {
                    inputRef.setInputVal(new Date(v)); // <---- Setting up default value
                },
                getValue: () => {
                    return inputRef.current.props.selected;
                    //return Date.toString();
                },
            });
        } else {
            registerField({
                name: fieldName,
                ref: inputRef.current,
                path: 'value',
                setValue(ref, value) {
                    ref.setInputValue(value);
                },
                clearValue(ref) {
                    ref.setInputValue('');
                },
            });
        }
    }, [fieldName, registerField]);

    useEffect(() => {
        // remove o estado de empty quando houver erros
        if (error) {
            setIsEmpty(false);
            setIsValid(false);
        }
    }, [error]);

    useEffect(() => {
        inputValue && inputValue(inputVal);
    }, [inputVal]);

    const fieldProps = {
        ...props,
        onBlur: handleInputBlur,
        onFocus: handleInputFocus,
        onChange: handleInputChange,
        onKeyUp: handleInputKeyUp,
        autoComplete: 'off',
        ref: inputRef,
        id: id,
        type: type,
        value: inputValue,
        rows: props.rows || 2,
    };

    if (typeof props.multline != 'undefined') {
        delete fieldProps.type;
        delete fieldProps.value;

        fieldProps.defaultValue = inputValue;
    } else {
        delete fieldProps.rows;
    }

    return (
        <>
            {label && <label htmlFor={id}>{label}</label>}

            {props?.tooltip && renderTooltip(props.tooltip)}

            <Container
                halfWidth={halfWidth}
                isEmailInvalid={isEmailInvalid}
                isTelInvalid={isTelInvalid}
                isCNPJInvalid={isCNPJInvalid}
                isValid={isValid}
                isFocused={isFocused}
                isTouched={isTouched}
                isEmpty={isEmpty}
                error={error}
                data-testid="input-container"
            >
                {isDatepicker ? (
                    <DatePicker
                        id={id}
                        selected={inputVal}
                        autoComplete="off"
                        onBlur={handleInputBlur}
                        onChange={handleDatepickerChange}
                        onSelect={handleDatepickerSelected}
                        value={inputVal}
                        dateFormat="dd/MM/yyyy"
                        name={fieldName}
                        ref={inputRef}
                    />
                ) : (
                    <>
                        {mask === 'currency' && (
                            <CurrencyPrefix>
                                <span>R$</span>
                            </CurrencyPrefix>
                        )}

                        {props.multline ? (
                            <textarea {...fieldProps} />
                        ) : (
                            <input {...fieldProps} />
                        )}
                    </>
                )}
                <div id="iconInput" />
                {isDatepicker && (
                    <div className="icon-datepicker">
                        <FiCalendar color="#464E59" size={16} />
                    </div>
                )}
            </Container>

            {error && (
                <Error>
                    <span>{error}</span>
                </Error>
            )}

            {isTelInvalid && !error && (
                <EmptyError>
                    <span>
                        {getErrorMessage(`Invalid${fieldName}`, props.lang)}
                    </span>
                </EmptyError>
            )}

            {isEmailInvalid && !error && (
                <EmptyError>
                    <span>
                        {getErrorMessage(`Invalid${fieldName}`, props.lang)}
                    </span>
                </EmptyError>
            )}

            {isCNPJInvalid && !error && (
                <EmptyError>
                    <span>
                        {getErrorMessage(`Invalid${fieldName}`, props.lang)}
                    </span>
                </EmptyError>
            )}

            {isEmpty && !error && (
                <EmptyError>
                    <span>{getErrorMessage(`EmptyDefault`, props.lang)}</span>
                </EmptyError>
            )}

            {!error && !isEmpty && props?.helptext && (
                <HelpText>{props?.helptext}</HelpText>
            )}
        </>
    );
};

Input.propTypes = {
    name: PropTypes.string.isRequired,
    halfWidth: PropTypes.bool,
    id: PropTypes.string || PropTypes.number,
};

export default Input;
