/* eslint-disable */

import React, { useContext, useState, useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Api from '../../services/api';
import Input from '../../components/Input';
import LogoiClips from '../../assets/logo-iclips.svg';
import Button from '../../components/Button';
//import { StepsContext } from '../../contexts/StepsContext';
import { AuthContext } from '../../contexts/AuthContext';
import getValidationErrors from '../../utils/getValidationErrors';

import {
    ContentForm,
    HeaderStep,
    Footer,
    FormGroup,
} from '../../styles/global';
import { I18nProvider, LOCALES } from '../../i18n';

const Comercial = (...props) => {
    const formRef = useRef(null);

    const [buttonLoading, setButtonLoading] = useState(false);
    const { handleLogin } = useContext(AuthContext);
    let history = useHistory();

    const handleValidateTokenToAuth = useCallback(async data => {
        try {
            setButtonLoading(true);

            // eslint-disable-next-line no-unused-expressions
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                tokenCode: Yup.string().required(
                    'Por favor, informe o token de acesso.',
                ),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            await Api.post('/Login', {
                userName: 'Bender',
                password: data.tokenCode,
                system: 7,
            })
                .then(res => {
                    if (res.status === 202) {
                        handleLogin(res.data, Api);
                        setButtonLoading(false);
                        history.push('/comercial/steps');
                    } else {
                        console.log(res);
                    }
                })
                .catch(err => {
                    // eslint-disable-next-line no-unused-expressions
                    formRef.current?.setErrors({
                        tokenCode: 'Token inválido.',
                    });
                })
                .finally(() => {
                    setButtonLoading(false);
                });
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                // eslint-disable-next-line no-unused-expressions
                formRef.current?.setErrors(errors);
            }
            setButtonLoading(false);
        }
    }, []);

    return (
        <I18nProvider locale={LOCALES.PORTUGUESE}>
            <ContentForm>
                <Form ref={formRef} onSubmit={handleValidateTokenToAuth}>
                    {props.children}
                    <HeaderStep>
                        <img src={LogoiClips} alt="iClips" width="166" height="54" />
                        <h2>Cadastro do Cliente</h2>
                    </HeaderStep>
                    <FormGroup>
                        <label htmlFor="tokenCode">Código de acesso</label>
                        <Input
                            name="tokenCode"
                            id="tokenCode"
                            placeholder="Informe o código de acesso"
                            type="text"
                        />
                    </FormGroup>
                    <Footer>
                        <Button
                            type="submit"
                            isLoading={buttonLoading}
                            disabled={buttonLoading}
                            primary
                        >
                            Começar
                        </Button>
                    </Footer>
                </Form>
            </ContentForm>
        </I18nProvider>
    );
};

export default Comercial;
