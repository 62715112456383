import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/reset.css';
import 'react-phone-number-input/style.css';
import { VERSION } from './utils/enums';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

var _v = document.createElement("span");
_v.innerHTML = VERSION.APP;
_v.style.display = "none";
document.getElementsByTagName('body')[0].appendChild(_v);