/* eslint-disable */

import styled, { css } from 'styled-components';
import { shade } from 'polished';
import printSteps from '../../../../utils/printSteps';
import { DEFAULT_COLORS } from '../../../../utils/colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${props =>
        !props.visible &&
        css`
            display: none;
        `}

    h2 {
        margin-bottom: 16px;
    }

    span {
        font-size: 16px;
        text-align: center;
        /* margin-bottom: 34px; */
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    width: 100%;
    margin-top: 34px;
    justify-content: center;
`;

export const TitleLastScreen = styled.div`
    h2 {
        margin-bottom: 24px;
        color: ${DEFAULT_COLORS.gray_800};
    }
`;
