export function cep(e) {
    e.currentTarget.maxLength = 9;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{5})(\d)/, '$1-$2');
    e.currentTarget.value = value;
    return e;
}

export function maskTelephone(e) {
    e.currentTarget.maxLength = 15;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    e.currentTarget.value = value;
    return e;
}

export function maskTelephoneNA(e) {
    let value = e.currentTarget.value;

    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{3})(\d)/g, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');

    e.currentTarget.value = value;
    return e;
}

export function currency(e) {
    e.currentTarget.maxLength = 9;

    if (e.currentTarget.value.length > 9) {
        // e.currentTarget.value = '';
        return false;
    }

    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
    e.currentTarget.value = value;

    return e;
}

export function date(e) {
    e.currentTarget.maxLength = 10;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d{2})(\d{2,4})/g, '$1/$2/$3');
    e.currentTarget.value = value;
    return e;
}

export function cnpj(e) {
    e.currentTarget.maxLength = 14;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, '');
    value = value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5',
    );
    e.currentTarget.value = value;
    return e;
}
