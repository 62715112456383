let colors_theme = {
    white: '#FFF',
    black: '#000',
    /* Gray Start */
    gray_50 : '#F7FAFD',
    gray_100: '#EDF1F7',
    gray_200: '#E2E7F0',
    gray_300: '#CBD2E0',
    gray_400: '#A0ABC0',
    gray_500: '#677389',
    gray_600: '#4A5568',
    gray_700: '#464E59',
    gray_800: '#1A202C',
    gray_900: '#171923',
    /* Gray End */
    /* Red Start */
    red_50: '#FFF6F6',
    red_100 : '#FFC7C7',
    red_200: '#FF9696',
    red_300: '#FD6969',
    red_400: '#F34242',
    red_500 : '#E01F1F',
    red_600: '#B61B1B',
    red_700: '#8A1919',
    red_800: '#561212',
    red_900: '#310D0D',
    /* Red End */
    /* Orange Start */
    orange_50: '#FFF9F6',
    orange_100: '#FFD0B8',
    orange_200: '#FFA77A',
    orange_300: '#F87E42',
    orange_400: '#F46421',
    orange_500: '#D43D02',
    orange_600: '#A42600',
    orange_700: '#7B1600',
    orange_800: '#4B0900',
    orange_900: '#330300',
    /* Orange End */
    /* Yellow Start */
    yellow_50: '#FFF9ED',
    yellow_100: '#FFECBB',
    yellow_200: '#FFDD87',
    yellow_300: '#FFB92E',
    yellow_400: '#DB7500',
    yellow_500: '#B35C00',
    yellow_600: '#883E01',
    yellow_700: '#742F01',
    yellow_800: '#5F2102',
    yellow_900: '#3C0901',
    /* Yellow End */
    /* Green Start */
    green_50: '#EEFCF3',
    green_100: '#BDEFCE',
    green_200: '#88DFA5',
    green_300: '#46BD6F',
    green_400: '#2AA254',
    green_500: '#157F3F',
    green_600: '#0D6331',
    green_700: '#0F4D29',
    green_800: '#0B371F',
    green_900: '#072C19',
    /* Green End */
    /* Teal Start */
    teal_50: '#ECFCFC',
    teal_100: '#C1F9F8',
    teal_200: '#8FEBEA',
    teal_300: '#39D2D2',
    teal_400: '#009E9E',
    teal_500: '#008080',
    teal_600: '#006666',
    teal_700: '#004D4D',
    teal_800: '#003333',
    teal_900: '#062424',
    /* Teal End */
    /* Blue Start */
    blue_50: '#F5F8FF',
    blue_100: '#C0D6FF',
    blue_200: '#8BB3FD',
    blue_300: '#5C93F7',
    blue_400: '#3576ED',
    blue_500: '#225ED8',
    blue_600: '#1649BC',
    blue_700: '#0F369B',
    blue_800: '#0B2574',
    blue_900: '#040C2A',
    /* Blue End */
    /* Cyan Start */
    cyan_50: '#EAFAFC',
    cyan_100: '#B9EFF9',
    cyan_200: '#8CE5F5',
    cyan_300: '#4DC5DB',
    cyan_400: '#1C9BB5',
    cyan_500: '#077C94',
    cyan_600: '#0A6275',
    cyan_700: '#094E5E',
    cyan_800: '#043542',
    cyan_900: '#022833',
    /* Cyan End */   
    /* Purple Start */
    purple_50: '#F9F7FF',
    purple_100: '#DAD2FF',
    purple_200: '#BAADFC',
    purple_300: '#9D8AF5',
    purple_400: '#826DEB',
    purple_500: '#6B58D6',
    purple_600: '#5647BB',
    purple_700: '#42379A',
    purple_800: '#2D2673',
    purple_900: '#141236',
    /* Purple End */
    /* Pink Start */
    pink_50: '#FFF5F9',
    pink_100: '#FFC2D9',
    pink_200: '#FF90BB',
    pink_300: '#FC639F',
    pink_400: '#F23F87',
    pink_500: '#D6236D',
    pink_600: '#B21056',
    pink_700: '#89063F',
    pink_800: '#590129',
    pink_900: '#320117',
    /* Pink End */    
};

/* Validations Colors - Start */
colors_theme = Object.assign({
        red_validation: colors_theme.red_300,
        orange_validation: colors_theme.orange_300,
        green_validation: colors_theme.green_300
    },
    colors_theme
);
/* Validations Colors - Finish */

export const DEFAULT_COLORS = colors_theme;