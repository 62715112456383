/* eslint-disable */

import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useField } from '@unform/core';
import { getErrorMessage } from '../../utils/manualValidations';

import { Container, StyledSelect, Error, EmptyError } from './styles';
import { isEmpty } from 'lodash';

const InputSelect = ({ name, options, onChange, ...rest }) => {

    const selectRef = useRef(null);

    const [isValid, setIsValid] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const { fieldName, error, defaultValue, registerField, clearError } = useField(name);
    const [selected, setSelected] = useState('');

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: ref => {
                if (rest.isMulti) {
                    if (!ref.value) {
                        return [];
                    }
                    return ref.value.map(option => option.value);
                }
                if (!ref.value) {
                    return '';
                }
                return ref.value;
            },
            setValue: (ref, value) => {
                ref.value = value;
            },
            clearValue: ref => {
                ref.value = null;
            },
        });
    }, [fieldName, registerField, rest.isMulti]);

    const handleChange = useCallback(e => {
        const value = e.target.value;

        if(value){
            clearError();
            setIsEmpty(false);
            setIsValid(true);
        }else{
            setIsEmpty(true);
            setIsValid(false);
        }

        setSelected(value);

        onChange && onChange(value);
    }, []);

    const handleBlur = useCallback( e => {
        const value = e.target.value;
        clearError(fieldName);

        if( !value ){
            setIsEmpty(true);
            setIsValid(false);
        }else{
            setIsEmpty(false);
            setIsValid(true);
        }

    },[]);

    useEffect(() => {
        // remove o estado de empty quando houver erros
        if( error ){
            setIsEmpty(false);
            setIsValid(false);
        }
    }, [error]);

    return (
        <>
            <Container
                isValid={isValid}
                isEmpty={isEmpty}
                error={error}
            >
                <StyledSelect
                    defaultValue={defaultValue}
                    ref={selectRef}
                    {...rest}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={selected}
                    name={name}
                >
                    {options.map(option => (
                        <option key={option.value+Math.floor(Math.random() * 100) + 1} value={option.value}>
                            {option?.label||option.value}
                        </option>
                    ))}
                </StyledSelect>
            </Container>

            {error && (
                <Error>
                    <span>{error}</span>
                </Error>
            )}

            {isEmpty && (
                <EmptyError>
                    <span>{getErrorMessage('selectAtLeastOneOption', rest.lang)}</span>
                </EmptyError>
            )}
        </>
    );
};

export default InputSelect;
