/* eslint-disable */

import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useField } from '@unform/core';
import { getErrorMessage } from '../../utils/manualValidations';
import { Container, Error, EmptyError, Prepend } from './styles';
import { HelpText } from '../../styles/global';
import Api from '../../services/api';

export default function InputAsync({
    name,
    formRef,
    inputValue,
    onChange,
    onBlur,
    onFocus,
    ...props
}) {
    //const formRef = useRef(ref);
    const inputRef = useRef(null);

    const [isValid, setIsValid] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputVal, setInputVal] = useState('');

    const {
        fieldName,
        defaultValue,
        error,
        registerField,
        clearError,
    } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref, value) {
                ref.setInputValue(value);
            },
            clearValue(ref) {
                ref.setInputValue('');
            },
        });
    }, [fieldName, registerField]);

    useEffect(() => {
        if (isLoading) {
            props.nextStepDisabledButton(true);
            Api.get('/Domain?name=' + inputVal)
                .then(function (response) {
                    if (response.data[0].code === 404) {
                        // passou na validação do domínio
                        setIsValid(true);
                        clearError(fieldName);
                        props.nextStepDisabledButton(false);
                    } else if (response.data[0].code === 202) {
                        // já existe um domínio igual ao informado
                        setErrorField(getErrorMessage('domainNameExists',props.lang));
                        setIsValid(false);
                    }
                })
                .catch(function (error) {
                    setErrorField(getErrorMessage('Error_500',props.lang));
                })
                .finally(function (response) {
                    //always
                    setIsLoading(false);
                });
        }
        props.isLoadingNextStep(isLoading);
    }, [isLoading]);

    function setErrorField(errorMessage) {
        // eslint-disable-next-line no-unused-expressions
        formRef?.current.setFieldError(fieldName, errorMessage);
    }

    function handleInputBlur( e ){
        const value = e.target.value;
        const type = e.currentTarget.type;

        clearError(fieldName);
        setIsLoading(false);

        if (!value) {
            setIsEmpty(true);
            setIsValid(false);
        } else {
            const domainRegex = /^[a-z0-9]{1,}$/g;

            if (props?.minLength && props.minLength > value.length) {
                setErrorField( getErrorMessage('MinClientDomain',props.lang) );
            } else if (props?.maxLength && props.maxLength < value.length) {
                setErrorField( getErrorMessage('MaxClientDomain',props.lang) );
            } else if (!domainRegex.test(value)) {
                setErrorField( getErrorMessage('domainNameAllowedCharacters',props.lang));
            } else {
                setIsLoading(true);
                setIsEmpty(false);
                setIsValid(false);
            }
        }

        onBlur && onBlur(e);
    }

    const handleInputChange = useCallback(e => {
        const value = e.target.value;
        setInputVal(value);
        props.nextStepDisabledButton(true);

        onChange && onChange(value);
    }, []);

    const handleInputFocus = useCallback( () => {
        onFocus && onFocus();
    }, []);

    useEffect(() => {
        // remove o estado de empty quando houver erros
        setIsEmpty(false);
        setIsValid(false);
    }, [error]);

    useEffect(() => {
        inputValue && inputValue(inputVal);
    }, [inputVal]);

    return (
        <>
            <Container
                isValid={isValid}
                isFocused={isFocused}
                isTouched={isTouched}
                isEmpty={isEmpty}
                isLoading={isLoading}
                error={error}
                autoComplete="off"
                {...props}
            >
                {props?.prepend && <Prepend>{props.prepend}</Prepend>}
                <input
                    id={fieldName}
                    name={fieldName}
                    ref={inputRef}
                    placeholder={props.placeholder}
                    defaultValue={defaultValue}
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    autoComplete="off"
                    disabled={isLoading}
                    data-testid="test-inputAsync"
                />
                <div id="iconInput" />
            </Container>

            {error && (
                <Error>
                    <span>{error}</span>
                </Error>
            )}

            {isEmpty && (
                <EmptyError>
                    <span>{getErrorMessage('domainName', props.lang)}</span>
                </EmptyError>
            )}

            {isLoading && (
                <EmptyError>
                    <span>{getErrorMessage('VerifyDomainNameAvailability',props.lang)}</span>
                </EmptyError>
            )}

            {!error && !isEmpty && !isLoading && props?.helpText && (
                <HelpText>{props.helpText}</HelpText>
            )}
        </>
    );
}
