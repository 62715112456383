/* eslint-disable */

import styled, { css } from 'styled-components';
import shade from 'polished';
import { DEFAULT_COLORS } from '../../utils/colors';

import ErrorInputIcon from '../../assets/alert-circle-red.svg';
import EmptyInputIcon from '../../assets/alert-circle-yellow.svg';
import ValidInputIcon from '../../assets/check.svg';
import LoadingInputIcon from '../../assets/loader.svg';

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #00000025;
    border-radius: 4px;
    overflow: hidden;

    #iconInput {
        display: none;
        min-width: 38px;
        height: 38px;
        margin-right: 8px;

        ${props => props.custom == 'domain' && css``}

        ${props =>
            props.isValid &&
            css`
                display: block;
                background: url(${ValidInputIcon}) no-repeat center;
            `};

        ${props =>
            props.isEmpty &&
            css`
                display: block;
                background: url(${EmptyInputIcon}) no-repeat center;
            `};

        ${props =>
            props.error &&
            css`
                display: block;
                background: url(${ErrorInputIcon}) no-repeat center;
            `};

        ${props =>
            props.isLoading &&
            css`
                display: block;
                background: url(${LoadingInputIcon}) no-repeat center;
                background-size: contain;
            `};
    }

    ${props =>
        props.halfWidth &&
        css`
            width: 50%;
        `};

    ${props =>
        props.isEmpty &&
        css`
            border: 1px solid ${DEFAULT_COLORS.orange_validation};
            margin-bottom: 0;
        `};

    ${props =>
        props.isEmailInvalid &&
        css`
            border: 1px solid ${DEFAULT_COLORS.orange_validation};
            margin-bottom: 0;
        `};

    ${props =>
        props.isTelInvalid &&
        css`
            border: 1px solid ${DEFAULT_COLORS.orange_validation};
            margin-bottom: 0;
        `};

    ${props =>
        props.error &&
        css`
            border: 1px solid ${DEFAULT_COLORS.red_validation};
            margin-bottom: 0;
        `};

    ${props =>
        props.error &&
        css`
            border: 1px solid ${DEFAULT_COLORS.red_validation};
            margin-bottom: 0;
        `};

    ${props =>
        props.isValid &&
        css`
            border: 1px solid ${DEFAULT_COLORS.green_validation};
            margin-bottom: 0;
        `};

    input {
        width: 100%;
        background: transparent;
        height: 40px;
        padding: 6px 12px;
        border: none;
        font-size: 16px;
        color: ${DEFAULT_COLORS.gray_900};

        &::placeholder {
            color: #464e5980;
        }
    }

    svg {
        margin-right: 12px;
        display: none;

        ${props =>
            props.isEmpty &&
            css`
                display: block;
                color: ${DEFAULT_COLORS.orange_validation};
            `};

        ${props =>
            props.error &&
            css`
                display: block;
                color: ${DEFAULT_COLORS.red_validation};
            `};

        ${props =>
            props.isValid &&
            css`
                display: block;
                color: ${DEFAULT_COLORS.green_validation};
            `};
    }

    & .react-datepicker-wrapper {
        width: 100%;
    }

    & .icon-datepicker {
        background-color: ${DEFAULT_COLORS.gray_200};
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        min-width: 40px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    & .icon-datepicker svg {
        display: block;
        margin-right: 0;
    }
`;

export const Prepend = styled.div`
    display: flex;
    flex: 1;
    flex-grow: 0;
    border-right: 1px solid ${DEFAULT_COLORS.gray_300};
    height: 40px;
    color: ${DEFAULT_COLORS.gray_600};
    background-color: ${DEFAULT_COLORS.gray_100};
    font-size: 16px;
    line-height: 20px;
    padding: 10px 12px;
`;

export const Error = styled.div`
    span {
        font-size: 12px;
        color: ${DEFAULT_COLORS.red_validation};
    }
`;

export const EmptyError = styled.div`
    span {
        font-size: 12px;
        color: ${DEFAULT_COLORS.orange_validation};
    }
`;

export const CurrencyPrefix = styled.div`
    display: flex;
    padding: 10px;
    border-radius: 4px;
    background: ${DEFAULT_COLORS.gray_200};

    span {
        color: ${DEFAULT_COLORS.gray_600};
    }
`;
