import { LOCALES } from '../locales';

export default {
    [LOCALES.SPANISH]: {
        'loading-label': 'Cargando...',
        'backBtn-label': 'Volver',
        'nextBtn-label': 'Avanzar',
        'startNowBtn-label': 'Comenzar ahora',
        'yes-label': 'Si',
        'no-label': 'No',
        'dontKnow-label': 'No sé',
        'select-one-below': 'Seleccionar una de las siguientes opciones:',
        'publicity-label': 'Publicidad',
        'branding-label': 'Branding',
        'contentMkt-label': 'Marketing de contenidos',
        'design-label': 'Design',
        'pressOffice-label': 'Oficina de prensa',
        'seoSem-label': 'SEO/SEM',
        'socialMedia-label': 'Social media',
        'webAndMobileDesign-label': 'Web design y mobile design',
        'noneOptionOfTheAbove-label': 'Ninguna de las anteriores',
        '1a5-label': '1 hasta 5',
        '5a10-label': '5 hasta 10',
        '10a25-label': '10 hasta 25',
        '25a50-label': '25 hasta 50',
        'moreThan50-label': 'mas de 50',
        'iclipsDomain-helper-label':
            'Esta será la URL de dominio de sus iClips',

        'terms-of-service-agreement1': 'Al hacer clic en ',
        'terms-of-service-agreement2': '"Comenzar ahora"',
        'terms-of-service-agreement3': ' acepta con los ',
        'terms-of-service-agreement4': 'Términos de Servicio.',

        'client-step01-title':
            'Administre e integre toda su agencia de forma gratuita',
        'client-step01-client-exists': `Hemos identificado que ya eres cliente de Rock Content. Para continuar, ingrese su contraseña e inicie sesión en su cuenta Rock.`,
        'client-step01-subTitle':
            'iClips es la solución ideal para agencias y equipos de marketing.',
        'client-step01-label-name': `Nombre completo`,
        'client-step01-forgot-password': `Forgot my password`,
        'client-step01-label-email': `E-mail corporativo`,
        'client-step01-label-phone': `Teléfono`,
        'client-step01-signUpBtn-label': `¡Registrate gratis!`,
        'client-step01-login-label': `Hacer Login`,
        'client-step01-more-informations': `Necesitamos más información para crear tu iClips`,
        'client-step01-more-informations-sso': `Confirma tus datos para crear tu iClips`,
        'client-step02-title':
            '¿Su empresa presta algún servicio publicitario a terceros?',
        'client-step02-subTitle':
            'Algunos ejemplos son diseño web, marketing digital y SEO/SEM.',

        'client-step03a-title':
            '¿Cuál es el principal servicio que se realiza en su agencia?',
        'client-step03b-title':
            '¿Cuántas personas hay en su equipo de marketing?',

        'client-step04-title': 'Ya casi estás ahí...',
        'client-step04-nameOfCompany': '¿Cual es el nombre de la empresa?',
        'client-step04-numberEmployee': '¿Cuál es el número de empleados?',
        'client-step04-mostImportantTeam':
            '¿Qué es lo más importante para su equipo hoy?',
        'client-step04-mostImportantAgency':
            '¿Qué es lo más importante para su agencia hoy?',

        'client-step04-mostImportantOptionsVal0': 'Elija una opción',
        'client-step04-mostImportantOptionsVal1':
            'Integre la gestión de proyectos, medios y finanzas en una única plataforma',
        'client-step04-mostImportantOptionsVal2':
            'Mida el margen de ganancias / pérdidas de los clientes y proyectos de su agencia',
        'client-step04-mostImportantOptionsVal3':
            'Identifica y reduce las refracciones en tu agencia',
        'client-step04-mostImportantOptionsVal4':
            'Gestionar los proyectos y demandas de la agencia',
        'client-step04-mostImportantOptionsVal5':
            'Hacer la gestión financiera de la agencia',
        'client-step04-mostImportantOptionsVal6':
            'Gestionar la gestión de medios de la agencia',
        'client-step04-createYourDomain-label': 'Crea tu dominio',
        'client-step04-createYourDomain-helpLabel': 'Este será tu dominio',
        'client-step04-dontHaveCompany': 'No tengo empresa',
        'client-step04-Individual': 'Individual',
        'client-step04-confirmaEmail': 'Sus datos de acceso se enviarán a',
        'client-step04-yourDomainUrl': 'tu-dominio',

        'client-lastScreen-title': '¡Ahora eres parte de Rock!',
        'client-lastScreen-subTitle': 'Tu cuenta ha sido creada satisfactoriamente! Nos gustaría darle la bienvenida y decirle que estamos muy contentos de haber elegido nuestra solución para gestionar sus procesos.',
        'client-lastScreen-subTitle2': 'Se está creando su iClips y, en unos momentos, recibirá los datos de acceso en el correo electrónico',
        'client-lastScreen-btn-label': 'Continuar a la plataforma',

        'validation-userName': 'Ingrese el nombre de usuario.',
        'validation-email': 'Por favor ingrese el email.',
        'validation-phone': 'Teléfono inválido.',
        'validation-companyName': 'Introduzca el nombre de la empresa.',
        'validation-requiredField': 'Campo obligatorio',
        'validation-domainMinCharacters':
            'El dominio debe tener al menos 3 caracteres.',
        'validation-domainMaxCharacters':
            'El dominio debe tener un máximo de 15 caracteres.',
        'validation-verifyingDomain':
            'Comprobando la disponibilidad del dominio...',
        'validation-validDomain': 'Dominio valido',
        'validation-onlyLetters': 'Se permiten caracteres de la A a la z.',
        'validation-domainMustHaveMax': 'El dominio debe tener como máximo ',
        'validation-domainMustHaveMin': 'El dominio debe tener al menos ',
        'characters-label': ' caracteres.',
        'alert-text-error': 'Ocurrio un error. Vuelve a intentarlo en unos instantes. Si el error persiste, comuníquese con el soporte.',
        'SSOPasswordError': 'La contraseña ingresada no es correcta. Si el error persiste, comuníquese con el soporte.',
    },
};
