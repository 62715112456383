import React from 'react';
import {
    ContainerAlert
} from './styles';

import ErrorIcon from '../../assets/alert-circle-error.svg';
import InfoIcon from '../../assets/alert-circle-info.svg';

const Icon = (type) => {
    switch(type){
        case 'danger' :
            return ErrorIcon;
        case 'info' :
            return InfoIcon;
        default :
            return false;
    }
}

const Alert = ({children, type, icon}) => {
    return (
        <ContainerAlert type={type}>
            {
                icon && Icon(type) && <img src={Icon(type)} alt="Icon"/>
            }            
            <div>{children}</div>
        </ContainerAlert>
    );
};

export default Alert;