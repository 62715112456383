import styled, { css } from 'styled-components';
import { DEFAULT_COLORS } from '../../utils/colors';

export const Container = styled.div`

    border-color:${DEFAULT_COLORS.gray_300};
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;

    ${props =>
        props.isEmpty &&
        css`
            border-color: ${DEFAULT_COLORS.orange_validation};
        `};

    ${props =>
        props.error &&
        css`
            border-color: ${DEFAULT_COLORS.red_validation};
        `};

    ${props =>
        props.isValid &&
        css`
            border-color: ${DEFAULT_COLORS.green_validation};
        `};

`;

export const StyledSelect = styled.select`
    width: 100%;
    background: transparent;
    height: 40px;
    padding: 6px 12px;
    border: none;
    font-size: 16px;
    color: #464e5990;

    &::placeholder {
        color: #464e5980;
    }
`;

export const Error = styled.div`
    span {
        font-size: 12px;
        color: ${DEFAULT_COLORS.red_validation};
    }
`;

export const EmptyError = styled.div`
    span {
        font-size: 12px;
        color: ${DEFAULT_COLORS.orange_validation};
    }
`;
