/* eslint-disable */

import React, { useContext } from 'react';

import { StepsContext } from '../../../../../contexts/StepsContext';
import { OFFICE_STEP_VISIBLE } from '../../../../../utils/enums';
import { StepVisibleControl } from '../../../../../styles/global';

import Img from '../../../../../assets/lastScreen.png';
import LogoiClips from '../../../../../assets/logo-iclips.svg';
import { LogoImg, BodyImg, EndViewTextWelcome } from '../../../../../styles/global';
import { Container } from './styles';

const RegistrationCompleted = ({ ...props }) => {
    const { stepVisible } = useContext(StepsContext);
    return (
        <StepVisibleControl
            visible={stepVisible === OFFICE_STEP_VISIBLE.REGISTRATION_COMPLETED}
        >
            <Container>
                <LogoImg src={LogoiClips} alt="LogoiClips" width="166" height="54" />
                <BodyImg src={Img} alt="lastScreenImg" />

                <h2>Cadastro concluído 🎉</h2>

                <EndViewTextWelcome>
                    <span>O cadastro foi concluído com sucesso!<br />Dentro de alguns minutos, você receberá por e-mail os dados de acesso para atualizar o plano do cliente na página Meu Plano, em Configurações.</span>
                </EndViewTextWelcome>
            </Container>
        </StepVisibleControl>
    );
};

export default RegistrationCompleted;
