import React, { createContext, useState, useCallback, useEffect } from 'react';
import Api from '../../services/api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(false);
    const [loadingAuth, setLoadingAuth] = useState(true);

    useEffect(() => {
        const token = JSON.parse(sessionStorage.getItem('token'));

        if (token) {
            Api.defaults.headers.common['Authorization'] = token;
            setIsAuth(true);
        }

        setLoadingAuth(false);
    }, []);

    const handleLogin = useCallback(async (data, api) => {
        let token = data[0].message;
        sessionStorage.setItem('token', JSON.stringify(token));
        Api.defaults.headers.common['Authorization'] = token;
        api.defaults.headers.common['Authorization'] = token;
        setIsAuth(true);
    }, []);

    return (
        <>
            {!loadingAuth && (
                <AuthContext.Provider value={{ isAuth, handleLogin }}>
                    {children}
                </AuthContext.Provider>
            )}
        </>
    );
};
