import { LOCALES } from "../i18n";

const MVALIDATION_INPUT = {
    [LOCALES.PORTUGUESE] : {
        default: 'Por favor, verifique os erros.',
        EmptyDefault : 'Campo obrigatório.',

        EmptyCompanyContactName: 'Por favor, informe o principal contato na empresa.',
        EmptyEmailContact: 'Por favor, informe o email.',
        InvalidEmailContact: 'Endereço de e-mail inválido.',
        EmptyTelephone: 'Por favor, verifique o telefone.',
        InvalidTelephone: 'Número de telefone inválido.',
        MinTelephone : 'O Campo de telefone deve ter no mínimo 14 números.',
        MaxTelephone : 'O Campo de telefone deve ter no máximo 15 números.',
        InvalidLogin : 'Caracteres permitidos: (a-z 0-9).',
        MinLogin : 'O Campo de login deve ter no mínimo 2 caracteres.',
        MaxLogin : 'O Campo de login deve ter no máximo 50 caracteres.',
        EmptyLogin : 'Campo obrigatório. Caracteres permitidos: (a-z 0-9).',
        MinPassword : 'O Campo de senha deve ter no mínimo 6 caracteres.',
        MaxPassword : 'O Campo de senha deve ter no máximo 50 caracteres.',
        EmptyPassword : 'Por favor, informe o campo de senha.',
        MinConfirmPassword : 'O Campo de confirmar senha deve ter no mínimo 6 caracteres.',
        MaxConfirmPassword : 'O Campo de confirmar senha deve ter no máximo 50 caracteres.',
        EmptyConfirmPassword : 'Por favor, informe o campo de confirmar senha.',
        NotEqualsPassword : 'As senhas não são iguais.',
        InvalidPassword : 'Senha inválida. Sua senha deve ter no mínimo 6 caracteres e incluir ao menos um número, uma letra minúscula e outra maiúscula.',
        loginNotAllowed: 'O login admin não é permitido. Escolha outro login.',
        
        tokenCode: 'Por favor, informe o token.',
        EmptyCompanyName: 'Por favor, informe o nome da empresa.',
        EmptyEmployeesNumber : 'Por favor, informe o número de colaboradores.',
        
        Cnpj: 'Por favor, informe o CNPJ.',
        InvalidCnpj: 'CNPJ inválido.',
        Mrr: 'Por favor, informe o MRR.',
        TradingDate: 'Por favor, informe a data de negociação.',
        TradeRepresentative: 'Por favor, informe um representante comercial.',
        HubspotUrl: 'Por favor, informe o link do Hubspot.',
    
        name: 'Por favor, informe o nome do usuário.',
        email: 'Por favor, informe o e-mail.',
        phone: 'Por favor, informe o telefone.',
        domainName: 'Campo obrigatório. Caracteres permitidos: (a-z 0-9).',
        VerifyDomainNameAvailability : 'Verificando disponibilidade de domínio.',
        MinClientDomain : 'O domínio deve ter pelo menos 3 caracteres.',
        MaxClientDomain : 'O domínio deve ter no máximo 26 caracteres.',
        domainNameAllowedCharacters : 'Caracteres permitidos: (a-z 0-9).',
        domainNameExists : 'Já existe um domínio igual ao informado.',
        selectAtLeastOneOption : 'Escolha uma opção.',
        Error_500 : 'Erro 500.'
    },
    [LOCALES.ENGLISH] : {
        default: 'Please check for errors.',
        EmptyDefault : 'Required field.',
                
        EmptyCompanyContactName: 'Please inform the main contact at the company.',
        EmptyEmailContact: 'Please enter the email.',
        InvalidEmailContact: 'Invalid email address.',
        EmptyTelephone: 'Please check the telephone.',
        InvalidTelephone: 'Invalid phone number.',
        MinTelephone : 'The Telephone field must have a minimum of 14 numbers.',
        MaxTelephone : 'The Telephone field must have a maximum of 15 numbers.',
        
        InvalidLogin : 'Allowed characters: (a-z 0-9).',
        MinLogin : 'The Login field must be at least 6 characters',
        MaxLogin : 'The Login field must have a maximum of 50 characters.',
        EmptyLogin : 'Required field. Allowed characters: (a-z 0-9).',
        MinPassword : 'The Password field must be at least 6 characters',
        MaxPassword : 'The password field must have a maximum of 50 characters.',
        EmptyPassword : 'Please enter the password field.',
        MinConfirmPassword : 'The confirm password field must be at least 6 characters',
        MaxConfirmPassword : 'The confirm password field must have a maximum of 50 characters.',
        EmptyConfirmPassword : 'Please enter the confirm password field.',
        NotEqualsPassword : 'Passwords are not the same.',
        InvalidPassword : 'Invalid password. Your password must have at least 6 characters, one number, one uppercase and one lowercase character.',
        loginNotAllowed: 'Admin login is not allowed. Choose another login.',

        tokenCode: 'Please enter the token.',
        EmptyCompanyName: 'Please enter the name of the company.',
        EmptyEmployeesNumber : 'Please enter the number of employees.',

        Cnpj: 'Please inform the CNPJ.',
        InvalidCnpj: 'Invalid CNPJ.',
        Mrr: 'Please inform the MRR.',
        TradingDate: 'Please enter the trading date.',
        TradeRepresentative: 'Please inform a trade representative.',        
        HubspotUrl: 'Please enter the Hubspot link.',
    
        name: 'Please enter the user name.',
        email: 'Please enter your email.',
        phone: 'Please enter the phone.',
        domainName: 'Required field. Allowed characters: (a-z 0-9).',
        VerifyDomainNameAvailability : 'Checking domain availability.',
        MinClientDomain : 'The domain must be at least 3 characters long.',
        MaxClientDomain : 'The domain must be a maximum of 26 characters.',
        domainNameAllowedCharacters : 'Allowed characters: (a-z 0-9).',
        domainNameExists : 'There is already a domain equal to the one informed.',
        selectAtLeastOneOption: 'Choose an option.',
        Error_500 : 'Error 500.'
    },
};

export const getErrorMessage = (fieldName, LANG = LOCALES.PORTUGUESE) => {
    //debugger;
    return MVALIDATION_INPUT[LANG].hasOwnProperty(fieldName) ? MVALIDATION_INPUT[LANG][fieldName] : '';
};
