export const HOME_STEP_VISIBLE = {
    CUSTOMER_EMAIL: 1,
    LAST_SCREEN: 2,
};

export const OFFICE_STEP_VISIBLE = {
    CUSTOMER_INFORMATION: 1,
    REGISTRATION_COMPLETED: 2,
    // ADDITIONAL_INFORMATION: 2,
    // ICLIPS_INFORMATION: 3,
};

export const VERSION = {
    APP : '2.0.0'
};
