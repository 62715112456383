import React from 'react';

import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthContext';
import { StepsProvider } from './contexts/StepsContext';
import { Wrapper } from './styles/global';

const App = () => {
    return (
        <Wrapper>
            <BrowserRouter>
                <AuthProvider>
                    <StepsProvider>
                        <Routes />
                    </StepsProvider>
                </AuthProvider>
            </BrowserRouter>
        </Wrapper>
    );
};

export default App;
