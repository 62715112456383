import styled from 'styled-components';
import { DEFAULT_COLORS } from '../../utils/colors';
import IconCheckbox from '../../assets/checkbox.svg';

export const Container = styled.div`
    display:flex;
    align-items:center;
    
    & label {
        display: block;
        margin-bottom: 0;
        margin-left: -1.7rem;
        position: relative;
        z-index: 2;
        text-indent: 1.7rem;
        cursor: pointer;
    }
`;

export const Icon = styled.span`
    display:block;
    width: 1.15rem;
    height: 1.15rem;
    border-radius: .25rem;
    background-size: .75rem;
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${DEFAULT_COLORS.white};
    box-shadow: inset 0px 0px 0px 1px ${DEFAULT_COLORS.gray_300};
    margin-right: .5rem;
    flex-shrink: 0;
    position: relative;
    z-index:1;
`;

export const Input = styled.input`
    display:none;

    &:checked + span {
        background-color: ${DEFAULT_COLORS.blue_500};
        background-image: url('${IconCheckbox}');
        box-shadow: none;
    }
`;