/* eslint-disable */

import React, { useContext } from 'react';

import LogoiClips from '../../../../assets/logo-iclips.svg';
import Img from '../../../../assets/lastScreen.png';
import { HOME_STEP_VISIBLE } from '../../../../utils/enums';
import translate from '../../../../i18n/translate';

import { Container, TitleLastScreen } from './styles';
import { LogoImg, BodyImg, EndViewTextWelcome } from '../../../../styles/global';

import { StepsContext } from '../../../../contexts/StepsContext';

const LastScreen = () => {
    const { stepVisible, stepValues } = useContext(StepsContext);

    const { EmailContact } = stepValues;

    return (
        <Container visible={stepVisible === HOME_STEP_VISIBLE.LAST_SCREEN}>
            <LogoImg src={LogoiClips} alt="LogoiClips" width="166" height="54" />
            <BodyImg src={Img} alt="lastScreenImg" />

            <TitleLastScreen>
                <h2>{translate('client-lastScreen-title')} 🎉</h2>
            </TitleLastScreen>

            <EndViewTextWelcome>
                <span>{translate('client-lastScreen-subTitle2')} <strong>{EmailContact}</strong></span>
            </EndViewTextWelcome>
        </Container>
    );
};

export default LastScreen;
