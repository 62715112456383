/* eslint-disable */

import styled, { css } from 'styled-components';
import { DEFAULT_COLORS } from '../../utils/colors';
import { shade } from 'polished';

export const ButtonStyled = styled.button`
    border-radius: 0.25rem;
    padding: 0px 1rem;
    font-weight: 400;
    font-size: 1rem;
    color: ${DEFAULT_COLORS.white};
    background-color: #5B158C;
    height: 40px;
    border: 2px solid transparent;
    line-height: 1;
    width: ${props => (props.width ? `${props.width}px` : 'auto')};

    ${props => props.primary && css`
        background-color: #5B158C;

        &:hover {
            background-color: #7F26BF;
        }

        &:active {
            background-color: #7F26BF;
        }

        &:focus {
            background-color: #7F26BF;
            box-shadow: 0px 0px 0px 3px rgba(53,118,237,0.4);
        }
    `}

    ${props =>
        props.secondary &&
        css`
            background-color: transparent;
            color: ${DEFAULT_COLORS.gray_600};
            margin-right: 1rem;

            &:hover {
                color: ${DEFAULT_COLORS.blue_500};
                background-color: ${DEFAULT_COLORS.blue_50};
                border: 2px solid ${DEFAULT_COLORS.blue_100};
                box-shadow: 0px 2px 4px -1px rgba(34, 94, 216, 0.06),
                    0px 4px 6px -1px rgba(34, 94, 216, 0.1);
            }

            &:active {
                color: ${DEFAULT_COLORS.blue_700};
                background-color: ${DEFAULT_COLORS.blue_50};
                border: 2px solid ${DEFAULT_COLORS.blue_100};
            }

            &:focus {
                color: ${DEFAULT_COLORS.gray_600};
                background-color: transparent;
                border: 2px solid ${DEFAULT_COLORS.gray_50};
            }
        `}

    &:disabled {
        opacity: 0.3;
    }
`;
