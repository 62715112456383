/* eslint-disable */

import React, { useContext } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { Redirect } from 'react-router-dom';

import Home from './Home';
import Comercial from './Comercial';
import Steps from './Comercial/Steps';

const CustomRoute = ({ isPrivate, ...rest }) => {
    const { isAuth } = useContext(AuthContext);

    if (isPrivate && !isAuth) {
        return <Redirect to="/comercial" />;
    }

    return <Route {...rest} />;
};

const Routes = () => (
    <Switch>
        <CustomRoute path="/" exact component={Home} />
        <CustomRoute path="/comercial" exact component={Comercial} />
        <CustomRoute
            isPrivate
            path="/comercial/steps"
            exact
            component={Steps}
        />
        <CustomRoute path="*" component={Home} />
    </Switch>
);

export default Routes;
