/* eslint-disable */

import React, { useRef, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Input from '../../../../../components/Input';
import InputAsync from '../../../../../components/InputAsync';
import Button from '../../../../../components/Button';
import InputCheckbox from '../../../../../components/InputCheckbox';
import Alert from '../../../../../components/Alert';
import { Modal } from '../../../../../components/Modal';

import { StepsContext } from '../../../../../contexts/StepsContext';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { Form } from '@unform/web';

import * as Yup from 'yup';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { OFFICE_STEP_VISIBLE } from '../../../../../utils/enums';
import Api from '../../../../../services/api';

import {
    StepVisibleControl,
    FormGroup,
    Footer,
} from '../../../../../styles/global';

const StepCustomerInformation = ({ children }) => {
    const formRef = useRef(null);
    const history = useHistory();

    const { addStepValues, nextStep, stepVisible, stepValues } = useContext(StepsContext);

    const { isAuth } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState('');
    const [nextStepDisabledButton, setNextStepDisabledButton] = useState(false);
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [alertError, setAlertError] = useState(false);
    const [modalDataClient, setModalDataClient] = useState();

    const { REACT_APP_STUDIO_HOST } = process.env;

    // console.log('customer information: ', stepVisible);

    async function handleValidateStep(data) {
        try {
            setAlertError(false);

            // eslint-disable-next-line no-unused-expressions
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                CompanyName: Yup.string().required(
                    'Por favor, informe o nome da empresa.'
                ),
                DocumentNumber: Yup.string()
                    .required('Por favor, informe o CNPJ.')
                    .min(18, 'CNPJ inválido.'),
                CompanyContactName: Yup.string().required(
                    'Por favor, informe um contato na empresa.'
                ),
                EmailContact: Yup.string().required(
                    'Por favor, informe um e-mail de contato na empresa.'
                ),
                OaRepresentative: Yup.string().required(
                    'Por favor, informe o nome do representante.',
                ),
                OaRepresentativeEmail: Yup.string()
                    .required('Por favor, informe o e-mail do representante.')
                    .email('E-mail inválido.'),
                Telephone: Yup.string()
                    .min(14,"O Campo de telefone deve ter no mínimo 14 números.")
                    .max(15,"O Campo de telefone deve ter no máximo 15 números.")
                    .required('Campo de telefone inválido.'),
                ClientDomain: Yup.string().required( 'Por favor, informe o domínio.' )
                .min(3, 'O domínio deve ter pelo menos 3 caracteres.')
                .max(26, 'O domínio deve ter no máximo 26 caracteres.')
                .matches(/^[a-z0-9]{1,}$/g , 'Caracteres permitidos: (a-z 0-9)')
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            addStepValues(data);

            const finalObj = Object.assign(data, stepValues);

            setNextStepDisabledButton(true);

            Api.post('/Sales', finalObj)
                .then(res => {
                    if (res.status === 201) {
                        sessionStorage.removeItem('token');
                        nextStep({ isLastScreen: true });
                    }
                    setNextStepDisabledButton(false);
                })
                .catch(err => {
                    if (err?.response?.data[0]) {
                        setAlertError({
                            message : ((err?.response?.data.length && err.response.data[0]?.message) ? err.response.data[0].message : err.message),
                            status : err?.response?.status || 'Desconhecido'
                        });
                    }else{
                        setAlertError({
                            message : 'Network Error',
                            status : 'ERR_CONNECTION_TIMED_OUT'
                        });
                    }
                    setNextStepDisabledButton(false);
                    //caso tenha dado algum erro, mesmo apos forçar o RewriteRockID ele é retornado para false.
                });

        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                // eslint-disable-next-line no-unused-expressions
                formRef.current?.setErrors(errors);

                setFormErrors(errors);
            }
        }
    }

    // debugger;
    if (!isAuth) {
        // ********************** verificar necessidade e deletar
        // já existe a mesma lógica de validação no componente Steps,
        // mas para adicionar mais uma camada de validação no próprio componente, também adicionei neste.
        history.push('/comercial');
    }

    return (
        <>
            <StepVisibleControl
                visible={stepVisible === OFFICE_STEP_VISIBLE.CUSTOMER_INFORMATION}
            >
                <Form ref={formRef} onSubmit={handleValidateStep}>
                    {children}

                    <FormGroup>
                        <label htmlFor="CompanyName">Nome da empresa</label>
                        <Input
                            name="CompanyName"
                            id="CompanyName"
                            placeholder="Ex.: iPixel"
                            type="text"
                        />
                    </FormGroup>

                    <FormGroup>
                        <label htmlFor="Cnpj">CNPJ</label>
                        <Input
                            mask="cnpj"
                            name="DocumentNumber"
                            id="DocumentNumber"
                            placeholder=""
                            type="text"
                        />
                    </FormGroup>

                    <FormGroup>
                        <label htmlFor="CompanyContactName">
                            Principal contato na empresa
                        </label>
                        <Input
                            name="CompanyContactName"
                            id="CompanyContactName"
                            placeholder="Ex.: John Doe"
                            type="text"
                        />
                    </FormGroup>

                    <FormGroup>
                        <label htmlFor="EmailContact">Principal e-mail para contato</label>
                        <Input
                            name="EmailContact"
                            id="EmailContact"
                            placeholder="Ex.: abigail.silva@comercial.com"
                            type="email"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Input
                            type="tel"
                            mask="maskTelephone"
                            name="Telephone"
                            id="Telephone"
                            placeholder="xx x xxxx-xxxx"
                            label="Telefone"
                            maxLength={13}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label htmlFor="OaRepresentative">Nome do representante de vendas</label>
                        <Input
                            name="OaRepresentative"
                            id="OaRepresentative"
                            placeholder="Nome do representante"
                            type="text"
                        />
                    </FormGroup>

                    <FormGroup>
                        <label htmlFor="OaRepresentativeEmail">E-mail do representante de vendas</label>
                        <Input
                            name="OaRepresentativeEmail"
                            id="OaRepresentativeEmail"
                            placeholder="representante@iclips.com.br"
                            type="email"
                        />
                    </FormGroup>

                    <FormGroup>
                        <label htmlFor="ClientDomain">
                            Informe o domínio do cliente
                        </label>
                        <InputAsync
                            custom="domain"
                            type="text"
                            placeholder="dominiodocliente"
                            name="ClientDomain"
                            minLength={3}
                            maxLength={26}
                            prepend={REACT_APP_STUDIO_HOST}
                            helpText=""
                            formRef={formRef}
                            nextStepDisabledButton={setNextStepDisabledButton}
                            isLoadingNextStep={setIsLoadingButton}
                        />
                    </FormGroup>

                    { alertError && <FormGroup>
                        <Alert type="danger" icon={true}>
                            <>
                                <div>Ocorreu um erro. Tente novamente em alguns instantes. Caso o erro persista, entre em contato com o suporte.</div>
                                <div>Status: {alertError.status}</div>
                                <div>Message: {alertError.message}</div>
                            </>
                        </Alert>
                    </FormGroup> }

                    <Footer>
                        <Button type="submit"
                            primary
                            disabled={nextStepDisabledButton}
                            isLoading={isLoadingButton}
                        >
                            Cadastrar
                        </Button>
                    </Footer>
                </Form>
            </StepVisibleControl>
        </>
    );
};

export default StepCustomerInformation;
