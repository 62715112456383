/* eslint-disable */

import React, { useCallback, useContext, useEffect, useState } from 'react';

import { I18nProvider, LOCALES } from '../../i18n';

import BrazilIcon from '../../assets/Brazil.svg';
import EUAIcon from '../../assets/EUA.svg';

import CustomerEmail from './Steps/CustomerEmail';
import useQuery from '../../hooks/useQuery';

import LastScreen from './Steps/LastScreen';

import { useLocation } from 'react-router-dom';

import { StepsContext } from '../../contexts/StepsContext';

import { ContentForm, LanguageSwitch, StepCounter } from '../../styles/global';

import { HeaderMain } from '../../styles/global';

import hideHubspotFeature from '../../hooks/hideHubspotFeature';
import { REGIONS } from '../../i18n/regions';

const Home = () => {
    let query = useQuery();

    const [language, setLanguage] = useState(LOCALES.PORTUGUESE);
    const { region, setRegion, stepVisible, isStepCounterVisible, addStepValues, setContextCurrentLanguage } = useContext(StepsContext);

    const urlParams = new URLSearchParams(useLocation().search);

    useEffect(() => {
        let referrerParams = "";

        if (document.referrer !== "") {
            const referrer = new URL(document.referrer);
            referrerParams = new URLSearchParams(referrer.searchParams);
        }

        const queryString = new URLSearchParams({
            ...Object.fromEntries(referrerParams),
            ...Object.fromEntries(urlParams)
        });

        const tokenUrl = {
            token: queryString.get('token')
        };

        const paramsForHubspot = {
            utm_source: queryString.get('utm_source'),
            utm_medium: queryString.get('utm_medium'),
            utm_campaign: queryString.get('utm_campaign'),
            utm_id: queryString.get('utm_id'),
            utm_term: queryString.get('utm_term'),
            utm_content: queryString.get('utm_content')
        }

        addStepValues(paramsForHubspot);

        if (tokenUrl.token) {
            addStepValues(tokenUrl);
        }

        addStepValues({ RockID: queryString.get('rockid') || "" });

        setContextCurrentLanguage(language);
    }, []);

    const handleSetLanguage = useCallback(language => {
        setLanguage(language);
    }, []);

    useEffect(() => {
        let region = query.get("region");
        if (region) {
            if (region === REGIONS.NORTH_AMERICA) {
                setLanguage(LOCALES.ENGLISH);
                setRegion(REGIONS.NORTH_AMERICA);
                hideHubspotFeature();
            }
        }
    }, []);

    useEffect(() => {
        setContextCurrentLanguage(language);
    }, [language])

    return (
        <I18nProvider locale={language}>
            <HeaderMain>
                <LanguageSwitch language={language}>
                    {region == REGIONS.BRAZIL ? <>
                        <img
                            title="Português"
                            width={32}
                            src={BrazilIcon}
                            onClick={() => handleSetLanguage(LOCALES.PORTUGUESE)}
                            alt={LOCALES.PORTUGUESE}
                            className={language === LOCALES.PORTUGUESE ? 'active' : ''}
                        />
                        <img
                            title="English"
                            width={32}
                            src={EUAIcon}
                            onClick={() => handleSetLanguage(LOCALES.ENGLISH)}
                            alt={LOCALES.ENGLISH}
                            className={language === LOCALES.ENGLISH ? 'active' : ''}
                        />
                    </> : <></>}
                </LanguageSwitch>
            </HeaderMain>

            <ContentForm>
                <CustomerEmail />
                <LastScreen />
            </ContentForm>
        </I18nProvider>
    );
};

export default Home;
