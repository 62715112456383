/* eslint-disable */

import React, { createContext, useState, useEffect, useCallback } from 'react';
import { LOCALES } from '../../i18n';
import { REGIONS } from '../../i18n/regions';

export const StepsContext = createContext({});

export const StepsProvider = ({ children }) => {
    const [currentLanguage, setCurrentLanguage] = useState(LOCALES.ENGLISH);
    const [stepValues, setStepValues] = useState({});
    const [stepVisible, setStepVisible] = useState(null);
    const [region, setRegion] = useState(REGIONS.BRAZIL);
    const [loadingStep, setLoadingStep] = useState(true);
    const [isStepCounterVisible, setIsStepCounterVisible] = useState(true);

    const setContextCurrentLanguage = useCallback( lang => {
        setCurrentLanguage(lang);
    });

    const addStepValues = useCallback(data => {
        let values = stepValues;
        let step = Object.assign(values, data);
        setStepValues(step);
    }, []);

    const nextStep = useCallback(step => {
        if (step?.isLastScreen) {
            setIsStepCounterVisible(false);
        }
        setStepVisible(prevStep => prevStep + 1);
    }, []);

    const prevStep = useCallback(step => {
        setStepVisible(prevStep => prevStep - 1);
    }, []);

    useEffect(() => {
        setStepVisible(1);
        setLoadingStep(false);
    }, []);

    return (
        <>
            {!loadingStep && (
                <StepsContext.Provider
                    value={{
                        addStepValues,
                        stepValues,
                        nextStep,
                        stepVisible,
                        prevStep,
                        isStepCounterVisible,
                        setContextCurrentLanguage,
                        currentLanguage,
                        setRegion,
                        region
                    }}
                >
                    {children}
                </StepsContext.Provider>
            )}
        </>
    );
};
