import React, { useEffect, useRef, useContext } from 'react';
import { StepsContext } from '../../contexts/StepsContext';

const Captcha = ({ sitekey, removeCaptchaError }) => {
    const { addStepValues } = useContext(StepsContext);
    
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js`;
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    window.getCaptchaValue = () => {
        // eslint-disable-next-line no-unused-expressions
        return window?.grecaptcha.getResponse() || false;
    }

    window.myCallbackrecaptcha = () => {
        addStepValues({ reCaptcha: true });
        removeCaptchaError();
    };

    const refRecaptcha = useRef();

    return (
        <div
            ref={refRecaptcha}
            className="g-recaptcha"
            data-sitekey={sitekey}
            data-callback="myCallbackrecaptcha"
        ></div>
    );
};

export default Captcha;
