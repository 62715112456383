import React from 'react';
import PropTypes from 'prop-types';
import { ButtonStyled } from './styles';
import translate from '../../i18n/translate';

const Button = ({ children, type, width, isLoading, ...rest }) => {
    return (
        <ButtonStyled
            type={type || 'button'}
            width={width}
            isLoading={isLoading || false}
            {...rest}
        >
            {isLoading ? translate('loading-label') : children}
        </ButtonStyled>
    );
};

Button.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string || PropTypes.number,
    name: PropTypes.string,
    label: PropTypes.string,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
};

export default Button;
