/* eslint-disable */

import React from 'react';
import translate from '../../i18n/translate';

const SupportLink = () => {
    return (
        <a href="mailto:suporte@iclips.com.br" target="_blank">
            {translate('support')}
        </a>
    );
};

export default SupportLink;
